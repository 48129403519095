import { useEffect, useReducer, useState } from "react";
import update from "immutability-helper";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  makeStyles,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add, FileCopy, RemoveCircle } from "@material-ui/icons";

import { copyText } from "./services/clipboard";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  ingredientSectionCard: {
    margin: theme.spacing(2, 0),
  },
  preview: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
    padding: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const defaultIngredientSections = [
  {
    headingComponent: "h4",
    headingText: "",
    text: "",
  },
];

const initialState = {
  ingredients: {
    headingComponent: "h3",
    text: "",
    sections: defaultIngredientSections,
  },
  instructions: {
    headingComponent: "h3",
    text: "",
  },
  name: {
    headingComponent: "h2",
    text: "",
  },
};

const hasIngredients = (ingredients) =>
  ingredients.sections.some(({ headingText }) => !!headingText.length) ||
  ingredients.sections.some(({ text }) => !!text.length);

const generateRecipeNameHtml = (name) =>
  `<${name.headingComponent} itemprop="name">${name.text}</${name.headingComponent}>`;

const generateIngredientsHeadingHtml = (ingredients) =>
  `<${ingredients.headingComponent}>Ingredients</${ingredients.headingComponent}>`;

const generateIngredientsSectionHeadingHtml = (section) =>
  section.headingText
    ? `<${section.headingComponent}>${section.headingText}</${section.headingComponent}>`
    : "";

const generateIngredientsSectionHtml = (section) =>
  `${generateIngredientsSectionHeadingHtml(section)}<ul>${section.text
    .split(/\r?\n/)
    .map((ingredient) => `<li itemprop="recipeIngredient">${ingredient}</li>`)
    .join("")}</ul>`;

const generateIngredientsHtml = (ingredients) => {
  if (!hasIngredients(ingredients)) {
    return "";
  }
  const heading = generateIngredientsHeadingHtml(ingredients);
  const sections = ingredients.sections
    .map(generateIngredientsSectionHtml)
    .join("");

  return `${heading}${sections}`;
};

const generateInstructionsHtml = (instructions) =>
  instructions.text
    ? `<${instructions.headingComponent}>Instructions</${
        instructions.headingComponent
      }><ol itemprop="recipeInstructions" itemscope itemtype="https://schema.org/ItemList">${instructions.text
        .split(/\r?\n/)
        .map(
          (instruction) => `<li itemprop="itemListElement">${instruction}</li>`
        )
        .join("")}</ol>`
    : "";

const generateHtml = (state) => {
  const name = generateRecipeNameHtml(state.name);
  const ingredients = generateIngredientsHtml(state.ingredients);
  const instructions = generateInstructionsHtml(state.instructions);

  return `<div itemscope itemtype="https://schema.org/Recipe" id="recipe">${name}${ingredients}${instructions}</div>`;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_INGREDIENT_SECTION":
      return update(state, {
        ingredients: {
          sections: {
            $push: defaultIngredientSections,
          },
        },
      });
    case "REMOVE_INGREDIENT_SECTION":
      return update(state, {
        ingredients: {
          sections: {
            $splice: [[action.payload, 1]],
          },
        },
      });
    case "SET_INGREDIENT_HEADING_TEXT":
      return update(state, {
        ingredients: {
          sections: {
            [action.payload.index]: {
              headingText: { $set: action.payload.value },
            },
          },
        },
      });
    case "SET_INGREDIENT_TEXT":
      return update(state, {
        ingredients: {
          sections: {
            [action.payload.index]: {
              text: { $set: action.payload.value },
            },
          },
        },
      });
    case "SET_INSTRUCTIONS":
      return update(state, {
        instructions: {
          text: { $set: action.payload },
        },
      });
    case "SET_RECIPE_NAME":
      return update(state, {
        name: {
          text: { $set: action.payload },
        },
      });
    default:
      throw new Error(`${action.type} not defined`);
  }
};

const App = () => {
  const [html, setHtml] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();

  useEffect(() => {
    setHtml(generateHtml(state));
  }, [state]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h1>Properties</h1>
          <TextField
            label="Recipe Name"
            value={state.name.text}
            onChange={({ target: { value } }) =>
              dispatch({ type: "SET_RECIPE_NAME", payload: value })
            }
            fullWidth
          />
          {state.ingredients.sections.map((section, index) => (
            <Card
              key={`ingredient_section_${index}`}
              className={classes.ingredientSectionCard}
            >
              <CardContent>
                <TextField
                  label="Ingredient Section Name"
                  value={section.headingText}
                  onChange={({ target: { value } }) =>
                    dispatch({
                      type: "SET_INGREDIENT_HEADING_TEXT",
                      payload: { value, index },
                    })
                  }
                  fullWidth
                />
                <TextField
                  label={`${section.headingText} Ingredients`}
                  value={section.text}
                  onChange={({ target: { value } }) =>
                    dispatch({
                      type: "SET_INGREDIENT_TEXT",
                      payload: { value, index },
                    })
                  }
                  multiline
                  rows={3}
                  fullWidth
                />
              </CardContent>
              {state.ingredients.sections.length > 1 ? (
                <CardActions>
                  <Tooltip title={`Remove ${section.headingText}`}>
                    <IconButton>
                      <RemoveCircle
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to remove this section?`
                            )
                          ) {
                            dispatch({
                              type: "REMOVE_INGREDIENT_SECTION",
                              payload: index,
                            });
                          }
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              ) : null}
            </Card>
          ))}
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              dispatch({
                type: "ADD_INGREDIENT_SECTION",
              })
            }
            startIcon={<Add />}
            className={classes.button}
          >
            Add Ingredient Section
          </Button>
          <TextField
            label="Instructions"
            value={state.instructions.text}
            onChange={({ target: { value } }) =>
              dispatch({ type: "SET_INSTRUCTIONS", payload: value })
            }
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h1>Preview</h1>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className={classes.preview}
          />
          <h1>HTML</h1>
          <TextField multiline disabled value={html} fullWidth />
          <Button
            color="primary"
            variant="contained"
            onClick={() => copyText(html)}
            startIcon={<FileCopy />}
            className={classes.button}
          >
            Copy HTML
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
